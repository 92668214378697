import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from '@styled-components';

const Dot = styled('div')``;

const opacity = keyframes`
  0% {
    opacity: 0.5;
  }
  
  32% {
    opacity: 1;
  }
  
  48% {
    opacity: 0.75;
  }
  
  60% {
    opacity: 0.5;
  }
  
  100% {
    opacity: 0.5;
  }
`;

export const Loader = ({ className }) => (
  <aside className={className}>
    <Dot />
    <Dot />
    <Dot />
  </aside>
);

Loader.propTypes = {
  className: PropTypes.string,
};

export default styled(Loader)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${Dot} {
    animation: ${opacity} 1.2s ease-out infinite;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    margin: 0.4rem;
    opacity: 0.5;
    height: 0.6rem;
    width: 0.6rem;

    &:nth-child(2) {
      animation-delay: 0.4s;
    }

    &:nth-child(3) {
      animation-delay: 0.8s;
    }
  }
`;
