import languages from 'langs';

export function CCA3(locale = '') {
  const [code] = locale.split(/[-_]/);
  const language = languages.where('1', code);

  if (!language) {
    return code;
  }

  return language['3'];
}

export function name(locale = '') {
  const [code, country] = locale.split(/[-_]/);
  const language = languages.where('1', code);

  if (!language) {
    return locale;
  }

  return `${language.name} (${country.toUpperCase()})`;
}

export default languages
  .all()
  .reduce((acc, language) => ({ ...acc, [language['1']]: language }), {});
