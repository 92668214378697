import React, { Component } from 'react';
import countries, { common } from '@countries';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import { intlDefault, intlShape, withIntl } from '@react-intl';
import styled, { withStyles, theme } from '@styled-components';
import _ from 'underscore';

import { withPrefix } from 'gatsby';

const Display = styled('div')``;
const Flag = styled('img').attrs(({ code = '' }) => ({
  src: `/images/flags/${code.toLowerCase()}.svg`,
}))``;
const Chevron = styled('img').attrs({
  src: withPrefix(`/images/icons/dropdown.svg`),
})``;

const US_CODE = 'US';

const list = countries.filter(country => country.callingCode[0]);

export const codes = _.uniq([
  list.find(country => country.cca2 === US_CODE).callingCode[0],
  ...list.map(country => country.callingCode[0]).filter(Boolean),
]).map(code => `+${code}`);

export class Country extends Component {
  options = _.memoize(() => {
    const {
      intl: { locale },
    } = this.props;

    const countriesList = list
      .map(country => ({
        name: `${common(country.cca2, locale)} +${country.callingCode[0]}`,
        value: `+${country.callingCode[0]}`,
        code: country.cca2,
      }))
      .sort((a, b) => a.code.localeCompare(b.code));

    return [
      countriesList.find(({ code }) => code === US_CODE),
      ...countriesList.filter(({ code }) => code !== US_CODE),
    ];
  });

  state = {
    value: '+1',
  };

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.number,
    // Indeed is used (above)
    // eslint-disable-next-line react/no-unused-prop-types
    intl: intlShape,
    name: PropTypes.string,
  };

  static defaultProps = {
    intl: intlDefault,
  };

  constructor(props) {
    super(props);
    this.onChange = event => {
      const value = event.target.value.match(/\+\d+/)[0];
      this.setState({ value });
    };
  }

  render() {
    const { className } = this.props;
    const { value } = this.state;

    const { code } = this.options().find(({ value: v }) => v === value);

    return (
      <div className={className}>
        <Chevron />
        <Display>
          <Flag code={code} />
        </Display>
        <select
          name="prefix"
          onChange={this.onChange}
          options={this.options()}
          value={value}
        >
          {this.options().map(
            ({ name: option, value: ignore, ...optionProps }) => (
              <option key={option} {...optionProps}>
                {option}
              </option>
            ),
          )}
        </select>
      </div>
    );
  }
}

export default _.compose(
  withStyles`
    position: relative;
    width: 7.2rem;
    height: 4.8rem;

    ${Display} {
      ${theme('--font-small')};
      align-items: center;
      background: #FFF; //Light
      border-radius: 0.6rem;
      border: 0.1rem solid ${rgba('#000F19', 0.1)};
      display: flex;
      height: 100%;
      justify-content: space-between;
      padding: 0 0.4rem 0 1rem;
      position: absolute;
      pointer-events: none;
      transition: border-color 0.2s ease-out, margin-bottom 0.2s ease-out;
      width: 100%;
      z-index: 1;

      ${Flag} {
        opacity: ${props => (props.disabled ? 0.1 : 1)};
        width: 2.8rem;
        height: 2rem;
      }

    }

    ${Chevron} {
      position: absolute;
      right: 0.4rem;
      top: 1.2rem;
      pointer-events: none;
      z-index: 3;
    }
    
    select {
      height: 100%;
      overflow:hidden;
      width: 100%;
      outline: none;
    }
  `,
  withIntl,
)(Country);
