import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

const Description = styled('p')``;
const Shadow = styled('div')``;
const Title = styled('h1')``;

const Header = ({ className, id }) => (
  <header className={className}>
    <Shadow />
    <Title capitalize translate={id ? 'AUDIT.TITLE' : 'DEMO.TITLE'}>
      {id ? 'AUDIT.TITLE' : 'DEMO.TITLE'}
    </Title>
    <Description>
      Exclusive Offer: Implementation Fee Waived for a Limited Time
    </Description>
  </header>
);

Header.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

export default styled(Header)`
  background-image: url(/images/wakabayashi.webp);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: white;
  padding: 3.2rem 0 3.2rem;
  position: relative;

  ${Title} {
    font-size: 2.8rem;
    position: relative;
  }

  ${Description} {
    font-size: 1.6rem;
    line-height: 2rem;
    margin: 2.4rem auto 0;
    max-width: 30rem;
    position: relative;
  }

  ${Shadow} {
    background-color: rgba(0, 53, 102, 0.4);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media screen and (min-width: 600px) {
    padding: 6.4rem 0 5.6rem;

    ${Title} {
      font-size: 5.6rem;
    }

    ${Description} {
      font-size: 3.5rem;
      line-height: 3.5rem;
      margin: 2.4rem auto 0;
      max-width: 70rem;
    }
  }
`;
